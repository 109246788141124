import React from "react"
import Drawer from "@material-ui/core/Drawer/Drawer"
import { makeStyles } from "@material-ui/core/styles/index"
import IconButton from "@material-ui/core/IconButton"
import Close from "@material-ui/icons/Close"
import List from "@material-ui/core/List"
import { Link } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import Button from "@material-ui/core/Button"

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    backgroundColor: "rgba(0, 0, 0, 0.85)",
    width: "28vw",
    color: "white",
    ["@media (max-width:780px)"]: {
      width: "65vw",
    },
  },
  close: {
    color: "#fff",
  },
  menu: {
    color: "#fff",
  },
  navLink: {
    color: "#929B91",
    textDecoration: "none",
    padding: "4px",
    fontSize: "1.2rem",
  },
  listItem: {
    padding: "10px 10px 10px 40px",
  },
  bookNowButton: {
    maxWidth: 160,
    marginTop: 30,
    marginLeft: 40,
    color: "#fff!important",
    fontSize: "12px!important",
    backgroundColor: "#929B91",
    borderRadius: "40px!important",
    padding: "1.0em 2em!important",
    textDecoration: "none!important",
    "&:hover": {
      textDecoration: "none!important",
      opacity: "0.5!important",
      backgroundColor: "#929B91!important",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "24px!important",
      marginBottom: "24px!important",
    },
  },
}))

const activeStyle = {
  color: "white",
  // background: 'rgba(255, 0, 0, 0.3)',
  textDecoration: "none",
  fontSize: "1.6rem",
}

const getUrl = (locale, url) => {
  // if (locale === "nl") {
  //   // No locale to url with nl
  //   return "/" + url + "/" BUGG last /
  // }
  if (locale === "nl") {
    if (url === "") {
      return "/"
    } else {
      // No locale to url with nl
      return "/" + url + "/"
    }
  }
  return `/${locale}/${url}/`
}

const NavMenu = (props) => {
  const classes = useStyles()
  const { intl } = props
  const locale = intl.locale

  return (
    <Drawer
      anchor="right"
      open={props.open}
      onClose={props.close}
      classes={classes}
    >
      <IconButton>
        <Close />
      </IconButton>

      <List className={classes.menu}>
        <li className={classes.listItem}>
          <Link
            exact
            to={getUrl(locale, "")}
            activeStyle={activeStyle}
            className={classes.navLink}
          >
            {" "}
            {intl.formatMessage({ id: "navDrawer.home" })}{" "}
          </Link>
        </li>
        <li className={classes.listItem}>
          <Link
            to={getUrl(locale, "about")}
            activeStyle={activeStyle}
            className={classes.navLink}
          >
            {" "}
            {intl.formatMessage({ id: "navDrawer.doctors" })}{" "}
          </Link>
        </li>
        <li className={classes.listItem}>
          <Link
            to={getUrl(locale, "reviews")}
            activeStyle={activeStyle}
            className={classes.navLink}
          >
            {" "}
            {intl.formatMessage({ id: "navDrawer.reviews" })}{" "}
          </Link>
        </li>
        <li className={classes.listItem}>
          <Link
            to={getUrl(locale, "treatments")}
            activeStyle={activeStyle}
            className={classes.navLink}
          >
            {" "}
            {intl.formatMessage({ id: "navDrawer.treatments" })}{" "}
          </Link>
        </li>
        <li className={classes.listItem}>
          <Link
            to={getUrl(locale, "fillers-and-btx")}
            activeStyle={activeStyle}
            className={classes.navLink}
          >
            {" "}
            {intl.formatMessage({ id: "navDrawer.fillers" })}{" "}
          </Link>
        </li>
        <li className={classes.listItem}>
          <Link
            to={getUrl(locale, "deep-plane-facelift")}
            activeStyle={activeStyle}
            className={classes.navLink}
          >
            {" "}
            {intl.formatMessage({ id: "navDrawer.deep" })}{" "}
          </Link>
        </li>
        <li className={classes.listItem}>
          <Link
            to={getUrl(locale, "male-facelift")}
            activeStyle={activeStyle}
            className={classes.navLink}
          >
            {" "}
            {intl.formatMessage({ id: "navDrawer.maleFaceLift" })}{" "}
          </Link>
        </li>
        <li className={classes.listItem}>
          <Link
            to={getUrl(locale, "rhinoplasty")}
            activeStyle={activeStyle}
            className={classes.navLink}
          >
            {" "}
            {intl.formatMessage({ id: "navDrawer.rhinoplasty" })}{" "}
          </Link>
        </li>
        {/* <li className={classes.listItem}>
          <Link
            to={getUrl(locale, "beautician")}
            activeStyle={activeStyle}
            className={classes.navLink}
          >
            {" "}
            {intl.formatMessage({ id: "navDrawer.beautician" })}{" "}
          </Link>
        </li> */}
        <li className={classes.listItem}>
          <Link
            to={getUrl(locale, "3d-face-cosmetic-consultation")}
            activeStyle={activeStyle}
            className={classes.navLink}
          >
            {" "}
            {intl.formatMessage({ id: "navDrawer.ThreeD" })}{" "}
          </Link>
        </li>
        <li className={classes.listItem}>
          <Link
            to={getUrl(locale, "contact")}
            activeStyle={activeStyle}
            className={classes.navLink}
          >
            {" "}
            {intl.formatMessage({ id: "navDrawer.contact" })}{" "}
          </Link>
        </li>
        <li className={classes.listItem}>
          <Link
            to={getUrl(locale, "education")}
            activeStyle={activeStyle}
            className={classes.navLink}
          >
            {" "}
            {intl.formatMessage({ id: "navDrawer.education" })}{" "}
          </Link>
        </li>
      </List>
      {/* <Button
        className={classes.bookNowButton}
        onClick={props.close}
        data-leadbox-popup="FXQb5FVQxfBErkEBqV73v6"
        data-leadbox-domain="kooldigital.lpages.co"
      >
        Book now
      </Button> */}
      {/* <a
        href="https://schedule.clinicminds.com/?clinic=a8f5f0f3-1e41-11e9-881b-0a599d16f52a"
        className={`clinicminds-scheduler-button ${classes.bookNowButton}`}
      >
        Book now
      </a> */}
      {/* <Button
        onClick={(e) => {
          e.preventDefault()
          window.open(
            "https://schedule.clinicminds.com/?clinic=a8f5f0f3-1e41-11e9-881b-0a599d16f52a",
            "_blank",
            "width=800,height=600"
          )
        }}
        className={classes.bookNowButton}
      >
        Book now
      </Button> */}
    </Drawer>
  )
}

export default injectIntl(NavMenu)
